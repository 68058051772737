import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import { setRoute } from "@/__main__/router.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import {
  EVENT_PALWORLD_GAME_START,
  EVENT_PALWORLD_HEARTBEAT,
} from "@/game-palworld/constants/events.mjs";
import { GAME_SYMBOL_PALWORLD } from "@/game-palworld/definition.mjs";
import { findGameSymbol } from "@/util/game-route.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

async function init() {
  await initEvents;

  if (isRouteOverlay()) return;

  handleMessage(EVENTS.PALWORLD_HEARTBEAT, (payload) => {
    eventBus.emit(EVENT_PALWORLD_HEARTBEAT, payload);
  });

  handleMessage(EVENTS.PALWORLD_IS_RUNNING, (isRunning) => {
    if (!isRunning || findGameSymbol() === GAME_SYMBOL_PALWORLD) return;
    eventBus.emit(EVENT_PALWORLD_GAME_START);
    setRoute("/palworld/database");
  });
}

export default init;
